<template>
  <div class="about">
    <h3>About Beocraft Statistics</h3>
    <ul>
      <li>Counting since <strong>16-02-2023 20:00 CEST (Format: DD-MM-YY)</strong></li>
      <li>Powered by the <a href="https://mcstats.pequla.com/">MCSTATS API</a></li>
      <li>Session represents a player's time online between one join and leave</li>
      <li>Player details like name and unique id are logged by the application</li>
      <li>Avatars are provided kindy by <a href="https://visage.surgeplay.com/index.html">Visage</a></li>
    </ul>
  </div>
</template>
