<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary mb-3">
    <div class="container-fluid">
      <RouterLink class="navbar-brand" to="/">
        <img src="@/assets/logo.png" width="24" class="m-1">
        <span class="fw-bold">BeoSTAT</span>
      </RouterLink>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <RouterLink class="nav-link" to="/">Home</RouterLink>
          <RouterLink class="nav-link" to="/about">About</RouterLink>
          <RouterLink class="nav-link" to="/list">Last 30 Days</RouterLink>
          <RouterLink class="nav-link" to="/list/all">All Time</RouterLink>
        </div>
      </div>
    </div>
  </nav>
  <div class="container">
    <RouterView :key="$route.fullPath" />
    <footer class="text-center">
      <p>&copy; {{ year }} Beocraft - Made by <a href="https://pequla.com">Pequla</a></p>
    </footer>
  </div>
</template>

<script setup lang="ts">
const year = new Date().getFullYear()
</script>
