<template>
    <div class="list" v-if="players">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">PLAYER NAME</th>
                    <th scope="col">IN TOTAL</th>
                    <th scope="col">ACTION</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="player in players">
                    <th scope="row">
                        <img :src="`https://visage.surgeplay.com/face/32/${player.uuid}`" :alt="`${player.name}'s Icon'`">
                        {{ player.name }}
                    </th>
                    <td>{{ rnd(player.playtime) }} Hours</td>
                    <td>
                        <div class="btn-group">
                            <RouterLink :to="`/player/${player.uuid}`" class="btn btn-sm btn-outline-primary"
                                title="Playtime Details">
                                <i class="fa-solid fa-circle-info"></i>
                            </RouterLink>
                            <a :href="`https://admintools.app/app/player/${player.name}`"
                                class="btn btn-sm btn-outline-success" title="Go to Admin Tools" target="_blank">
                                <i class="fa-solid fa-share-from-square"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <Loading v-else />
</template>

<script setup lang="ts">
import { groupByPlayers, rnd } from '@/utils';
import { ref } from 'vue';
import Loading from './Loading.vue';

export interface PlaytimeListProps {
    promise: Promise<any>
}

const { promise } = defineProps<PlaytimeListProps>();
const players = ref<any[]>()
groupByPlayers(promise).then(data => {
    players.value = data.sort((p1, p2) => parseFloat(p2.playtime) - parseFloat(p1.playtime))
})
</script>