<template>
  <div class="home">
    <Bar v-if="chartData" :data="chartData" :options="chartOptions" class="mt-3" />
    <Loading v-else />
  </div>
</template>

<script lang="ts" setup>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { onMounted, ref } from 'vue';
import { groupByPlayers, rnd } from '@/utils';
import { BackendService } from '@/backend.service';
import Loading from '@/components/Loading.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const chartData = ref()
const chartOptions = {
  responsive: true,
  scales: {
    x: {
      ticks: {
        autoSkip: false
      }
    }
  }
}

onMounted(async () => {
  const longterm = await groupByPlayers(BackendService.getAllSessionsSinceDays(7))
  const today = await groupByPlayers(BackendService.getAllSessionsSinceDays(1))

  const combined: any[] = []
  longterm.forEach(p => {
    if (combined.map(c => c.name).includes(p.name)) {
      combined.forEach(c => {
        if (c.name === p.name) {
          c.longterm = p.playtime
        }
      })
      return
    }
    combined.push({
      name: p.name,
      longterm: p.playtime,
      today: 0
    })
  })
  today.forEach(p => {
    if (combined.map(c => c.name).includes(p.name)) {
      combined.forEach(c => {
        if (c.name == p.name) {
          c.today = p.playtime
        }
      })
      return
    }
    combined.push({
      name: p.name,
      longterm: 0,
      today: p.playtime
    })
  })

  chartData.value = {
    labels: combined.map(p => p.name),
    datasets: [
      {
        label: "Last 7 Days",
        data: combined.map(p => rnd(p.longterm)),
        backgroundColor: "darkorange"
      },
      {
        label: "Today",
        data: combined.map(p => rnd(p.today)),
        backgroundColor: "darkcyan"
      }
    ],
  }
})
</script>
