<template>
    <div class="player" v-if="player">
        <div class="text-center mx-auto mb-3">
            <h3>{{ player.name }}</h3>
            <img :src="`https://visage.surgeplay.com/bust/256/${player.uuid}`" alt="Avatar">
        </div>
        <table class="table table-striped table-hover">
            <tbody>
                <tr>
                    <th scope="row">TOTAL PLAYTIME</th>
                    <td>{{ rnd(player.playtime) }} Hours</td>
                </tr>
                <tr v-if="longest">
                    <th scope="row">LONGEST SESSION</th>
                    <td>{{ rnd(longest) }} Hours</td>
                </tr>
            </tbody>
        </table>
        <Pie :data="chartData" :options="options" v-if="chartData" class="mx-auto mb-3" />
        <div v-if="deaths != undefined && deaths.length > 0">
            <h3>Deaths:</h3>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">TIMESTAMP</th>
                        <th scope="col">MESSAGE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="death in deaths">
                        <th scope="row">{{new Date( death.createdAt).toLocaleString('sr-RS') }}</th>
                        <td>{{ death.message }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <Loading v-else />
</template>

<script setup lang="ts">
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'
import { BackendService } from '@/backend.service';
import { groupByPlayers, diff, rnd } from '@/utils';
import Loading from '@/components/Loading.vue'
import { ref } from 'vue';

ChartJS.register(ArcElement, Tooltip, Legend)

export interface PlayerProps {
    uuid: string
}

const { uuid } = defineProps<PlayerProps>()
const player = ref<any>()
const longest = ref<any>()
const chartData = ref<any>()
const deaths = ref<any[]>()
groupByPlayers(BackendService.getAllSessionsByPlayer(uuid)).then(data => {
    player.value = data[0]
    BackendService.getLongestSessionByPlayer(uuid).then(rsp => {
        longest.value = diff(rsp.data.joinedAt, rsp.data.leftAt)
        chartData.value = {
            labels: ['Total Playtime', 'Longest Session'],
            datasets: [
                {
                    backgroundColor: ['#41B883', '#E46651'],
                    data: [player.value.playtime, longest.value]
                }
            ]
        }
    })

    BackendService.getDeathsByPlayer(player.value.uuid).then(rsp => {
        deaths.value = rsp.data
    })
})

const options = {
    responsive: false
}
</script>