import axios from "axios"

const client = axios.create({
    baseURL: 'https://mcstats.pequla.com/api',
    headers: {
        'Accept': 'application/json'
    },
    validateStatus: function (status: number) {
        return status === 200
    }
})

export class BackendService {
    public static async getAllSessions() {
        return await client.get('/session/server/beocraft')
    }

    public static async getAllSessionsSinceDays(daysBefore: number = 10) {
        const date = new Date()
        const now = date.toISOString()
        const before = new Date(date.setDate(date.getDate() - daysBefore)).toISOString()
        return await client.get(`/session/server/beocraft?start=${before}&end=${now}`)
    }

    public static async getAllSessionsByPlayer(uuid: string) {
        return await client.get(`/session/server/beocraft/player/${uuid}`)
    }

    public static async getLongestSessionByPlayer(uuid: string) {
        return await client.get(`/session/server/beocraft/player/${uuid}/longest`)
    }

    public static async getDeathsByPlayer(uuid: string) {
        return await client.get(`/death/server/beocraft/player/${uuid}`)
    }
}