import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import PlaytimeList from '@/components/PlaytimeList.vue'
import { BackendService } from '@/backend.service'
import PlayerView from '@/views/PlayerView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      title: 'About'
    }
  },
  {
    path: '/list',
    name: 'list',
    component: PlaytimeList,
    props: {
      promise: BackendService.getAllSessionsSinceDays(30)
    },
    meta: {
      title: 'Last 30 Days'
    }
  },
  {
    path: '/list/all',
    name: 'all',
    component: PlaytimeList,
    props: {
      promise: BackendService.getAllSessions()
    },
    meta: {
      title: 'All Time'
    }
  },
  {
    path: '/player/:uuid',
    name: 'player',
    component: PlayerView,
    props: true,
    meta: {
      title: 'Player'
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title)
      document.title = `${to.meta.title} :: BeoStat`;
  next();
})

export default router
