export async function groupByPlayers(dataPromise: Promise<any>) {
    const rsp = await dataPromise
    const allPlayers: any[] = []

    if (rsp.data.length > 0) {
        rsp.data.forEach((session: any) => {
            const existing = allPlayers.find(player => player.uuid == session.player.uuid)
            if (existing) {
                existing.playtime = existing.playtime + diff(session.joinedAt, session.leftAt)
            }
            else {
                allPlayers.push({
                    name: session.player.name,
                    uuid: session.player.uuid,
                    playtime: diff(session.joinedAt, session.leftAt)
                })
            }
        })
    }

    return allPlayers
}

export function diff(join: string, leave: string | null): number {
    if (leave == null) return 0.0;

    const joinDate = new Date(join);
    const leaveDate = new Date(leave);

    if (isNaN(joinDate.getTime()) || isNaN(leaveDate.getTime())) {
        throw new Error('Invalid date string');
    }

    return Math.abs(leaveDate.getTime() - joinDate.getTime()) / 36e5;
}

export function rnd(num: any) {
    return Math.round((num + Number.EPSILON) * 100) / 100
}